import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { login } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "./accountStyle.css";
import { useHistory } from "react-router";

function LoginForm() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const userlogin = useSelector((state) => state.userLogin);
  const { userInfo, loading } = userlogin;
  const history = useHistory();
  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <div className="FormLogin">
      <div className="forForm">
        <div className="card">
          <div className="card-header bg-info">Log in</div>
          <div className="card-body">
            <Form
              className="formCont"

              onSubmit={submitHandler}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="btn w-100">
                log in
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
