import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOccupation,
  getOccupationAction,
  updateOccupation,
} from "../../actions/occupationAction";

function Occupation() {
  const dispatch = useDispatch();
  const [occupation, setOccupation] = useState();
  const [updateId, setUpadateId] = useState();
  const userlogin = useSelector((state) => state.userLogin);
  const getOccupation = useSelector((state) => state.getOccupation);
  const { getOccupations } = getOccupation;
  const { userInfo } = userlogin;

  useEffect(() => {
    dispatch(getOccupationAction(userInfo.token));
  }, []);
  const [error, setError] = useState("");
  const submitHandler = async (e) => {
    e.preventDefault();
    
    if (validate() == false) {
      return;
    }
    if (updateId == null) {
      dispatch(addOccupation(userInfo.token, occupation, reset));
      await dispatch(getOccupationAction(userInfo.token));
    } else {
      await dispatch(
        updateOccupation(userInfo.token, occupation, updateId, reset)
      );
      setUpadateId(null);
      await dispatch(getOccupationAction(userInfo.token));
    }
  };
  const editehandler = async (id, temppName) => {
    setOccupation(temppName);
    setError("");
    setUpadateId(id);
  };
  const reset = () => {
    setOccupation("");
    setError("");
    setUpadateId(null);
  };
  const occupationForm = () => {
    if (getOccupations) {
      return getOccupations.map((occupation ,index) => (
          <tr key={occupation.id}>
            <td>{index+1}</td>
            <td>{occupation.name}</td>
            <td>{occupation.created_at}</td>
            <td> <button
                type="button"
                className="btn btn-primary px-3"
                data-toggle="modal"
                data-target="#basicExampleModal"
                onClick={() =>{
                  editehandler(occupation.id, occupation.name)}}
              >
                <i className="far fa-edit" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>

      ));
    }
  };

  const validate = () => {
    
    if (occupation === "") {
      setError("required");
      return false;
    } else if (occupation.length < 2) {
      setError("too short");
      return false;
    } else {
      setError("");
      return true;
    }
  };
  return (
    <div className="card">
      <div className="card-header bg-info">
        <p>Add Occupation</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-6  col-sm-12">
            <form className="form" onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="Inputoccupation" className="col-form-label">
                  Occupation
                </label>
                <textarea
                  required
                  type="textArea"
                  className="form-control"
                  id="Inputoccupation"
                  placeholder="Occupation"
                  rows="3"
                  value={occupation}
                  onChange={(e) => {
                    setOccupation(e.target.value);
                    if (e.target.value) {
                      setError("");
                    }
                  }}
                />
                <div style={{ fontSize: 12, color: "red" }}>{error}</div>
                <button type="submit" className="btn btn-primary w-100 mt-3">
                  <i className="fas fa-plus"></i>{" "}
                  {updateId == undefined || updateId == null ? "Add" : "Save"}
                </button>
              </div>
            </form>
            {updateId != null && updateId != undefined ? (
              <div
                className="form-group"
                onClick={(e) => {
                 setOccupation("")
 setUpadateId(null)
                }}
              >
                <button className="btn btn-danger w-100">Cancel</button>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8 col-md-6  col-sm-12">
            <table className="table table-bordered mt-4">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light">
                  <th>#</th>
                  <th>occupation</th>
                  <th>create at</th>
                  <th>action</th>
                </tr>
              </thead>
             <tbody>{occupationForm()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Occupation;
