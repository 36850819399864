import React, { Component } from "react";
import "../printStyle.css";

class PageOfInvistigation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let array = [];

    this.props.state?.forEach((element, index) => {
      array.push(
        <tr key={index}>
          <td style={{ "font-size": "25px" }}>
            <div>{index + 1}</div>
          </td>
          <td style={{ "font-size": "25px" }}>
            <div>{element.test.name}</div>
          </td>
        </tr>
      );
    });

    return (
      <div style={{ width: "29.7cm" }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                className="text-white p-0"
                style={{ width: "5%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-3">#</div>
              </th>
              <th
                className="text-white p-0"
                style={{ width: "100%", "font-size": "25px" }}
              >
                <div className="bg-success m-0 p-3">Test Name</div>
              </th>
            </tr>
          </thead>
          <tbody>{array}</tbody>
        </table>
      </div>
    );
  }
}

export default PageOfInvistigation;
