import swal from "sweetalert";
import { createBrowserHistory } from "history";

import {
  ADD_PATINET,
  ADD_VISIT,
  DELETE_PATINET,
  EDIT_PATINET,
  EMPTY_LIST_PATINET,
  GET_PATINET,
} from "../../constants/patinetConstatnt";
import api, { logout } from "../../constants/api";
import store from "../../store";
import { getToken } from "../../reducers/userReducers";
import { DELETE_CVS } from "../../actions/pationactions";
import { FOLLOW_UP } from "./EachVisition/create_visit_reducer";

var dateFormat = require("dateformat");

export default function todayPatinetReducer(
  state = {
    visits: [],
    isAll: true,
    total: 0,
  },
  action
) {
  
  switch (action.type) {
    case GET_PATINET:
      return (state = {
        ...state,
        visits: action.payload.visits,
        total: action.payload.total,
        isAll: action.payload.isAll,
      });
    case EMPTY_LIST_PATINET:
      return (state = {
        ...state,
        visits: [],
      });
    case ADD_PATINET: {
      return {
        ...state,
        visits: [...state.visits, action.payload],
      };
    }

    case DELETE_PATINET: {
      const newDrugs = [...state.visits].filter((drug) => {
        
        return drug.id !== action.payload.id;
      });
      return {
        ...state,
        visits: [...newDrugs],
      };
    }

    case DELETE_CVS: {
      

      

      let myData = state.visits;
      const id = action.payload;

      myData.forEach((element, secIndex) => {
        let images = element.cvs;
        let temp = [];
        images.forEach((ex, index) => {
          
          if (ex.id != id) {
            temp.push(ex);
          }
        });
        myData[secIndex].cvs = temp;
      });
      
      return {
        ...state,

        visits: myData,
      };
    }
    case ADD_VISIT: {
      let temp = [...state.visits];
      const date = dateFormat(new Date(), "yyyy-mm-dd HH:mm:ss");
      temp.forEach((e) => {
        if (e.id == action.payload) {
          e.visit_last_one.follow_date = date;
          e.visit_last_one.created_at = date;
        }
      });
      return {
        ...state,
        visits: [...temp],
      };
    }
    case FOLLOW_UP: {
      let temp = [...state.visits];
      temp.forEach((e) => {
        if (e.id == action.payload) {
          e.visit_last_one.follow_date = dateFormat(
            new Date(),
            "yyyy-mm-dd HH:mm:ss"
          );
        }
      });
      return {
        ...state,
        visits: [...temp],
      };
    }
    default:
      return state;
  }
}

function formatDate(date ,param) {
  if(date==""||date==null||date==undefined){
    return param;
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export async function getPatient(from, to, query, page) {
  store.dispatch({ type: EMPTY_LIST_PATINET });
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  let url = `visits?from=${formatDate(from , "2021-01-01" )}&to=${formatDate(
    to, "3021-01-01"
  )}&_take=10&_page=${page}&`;
  if (isNaN(query.trim())) {
    url = url + "patient_name=" + query.trim();
  } else {
    url = url + "_filter=" + query.trim();
  }
  
  try {
    const response = await api.get(url, config);

    const data = response.data;
    

    
    data.visits.reverse();
    store.dispatch({ type: GET_PATINET, payload: { ...data, isAll: false } });
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
  }
}

export async function addPatinet(state, setState) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post("patients/create", state, config);

    const data = response.data;

    store.dispatch({ type: ADD_PATINET, payload: data });
    setState();
    await swal("Good job!", "Patient was added successfully", "success");
    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to add patient", "error");
    }
    return false;
  }
}

export async function upDatePatinet(state, id, token) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put("patients/update/" + id, state, config);

    const data = response.data;
    createBrowserHistory().back();
    store.dispatch({ type: EDIT_PATINET, payload: data });
    swal("Good job!", "Patient was updated successfully", "success");
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    }
    swal("Opps!", "Failed to update Patient", "error");
  }
}

export async function deletePatinet(id, token) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete(
      "patients/delete/" + id,

      config
    );

    const data = response.data;

    store.dispatch({ type: DELETE_PATINET, payload: { id: id } });
    swal("Good job!", "patients was deleted successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to delete patients", "error");
    if (error.response?.status === 401) {
      logout();
    }
  }
}

export async function getAllTodayPatient() {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  let url = `visits?from=${formatDate(new Date())}&to=${formatDate(
    new Date()
  )}&_take=10000&_page=0`;
  
  try {
    const response = await api.get(url, config);

    const data = response.data;
    

    
    data.visits.reverse();
    store.dispatch({ type: GET_PATINET, payload: { ...data, isAll: true } });
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    }
  }
}
