import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form as FormBot } from "react-bootstrap";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        spo2: "0",
        HR: "0",
        Temperature: "0",
        bplSystolic: "0",
        bplDisatolic: "0",
        bprSystolic: "0",
        bprDisatolic: "0",
        tsh: "0",
        SGPT: "0",
        rbs: "0",
        Hba1c: "0",
        height: "0",
        weight: "0",
        BMI: "0",
        sCreatinine: "0",
        sCholesterol: "0",
        EF: "0",
        sTG: "0",
        sLDL: "0",
        sHBL: "0",
        BNP: "0",
        troponin: "0",
        K: "0",
        sNA: "0",
        urea: "0",
        t4: "0",
        otherGynecologyObstetric: "",
      });
    }
  }, []);

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });
    setDisble(true);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateExamination(types, result, props.state.id, ids);
      setDisble(false);
    } else {
      await saveExamination(types, result, props.state.id);
      setDisble(false);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "lab-admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>

            {/**/}

            {/**/}
            <div className="card-body m-auto">
              {isAdmni == false ? null : (
                <div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="bplSystolic">bplSystolic</label>
                      <input
                        placeholder="bplSystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bplSystolic}
                        onChange={(e) => {
                          setState({ ...state, bplSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />

                      <label htmlFor="bplDisatolic">bplDisatolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="bplDisatolic"
                        type="number"
                        step="any"
                        value={state.bplDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bplDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="bprSystolic">bprSystolic</label>
                      <input
                        placeholder="bprSystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.bprSystolic}
                        onChange={(e) => {
                          setState({ ...state, bprSystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                      <label htmlFor="bprDisatolic">bprDisatolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="bprDisatolic"
                        type="number"
                        step="any"
                        value={state.bprDisatolic}
                        onChange={(e) => {
                          setState({ ...state, bprDisatolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <div className="">
                        <label htmlFor="spo2">spo2</label>
                        <input
                          disabled={isComplete}
                          placeholder="spo2"
                          type="number"
                          step="any"
                          value={state.spo2}
                          onChange={(e) => {
                            setState({ ...state, spo2: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>

                      <label htmlFor="HR">HR</label>
                      <input
                        placeholder="HR"
                        disabled={isComplete}
                        type="number"
                        step="any"
                        value={state.HR}
                        onChange={(e) => {
                          setState({ ...state, HR: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  {/* bl.urea */}

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sCreatinine">S.Creatinine</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.Creatinine"
                        type="number"
                        step="any"
                        value={state.sCreatinine}
                        onChange={(e) => {
                          setState({ ...state, sCreatinine: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sLDL">BL.Urea</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.Urea"
                        type="number"
                        step="any"
                        value={state.urea}
                        onChange={(e) => {
                          setState({ ...state, urea: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="SGPT">SGPT</label>
                      <input
                        disabled={isComplete}
                        placeholder="SGPT"
                        type="number"
                        step="any"
                        value={state.SGPT}
                        onChange={(e) => {
                          setState({ ...state, SGPT: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  {/* s.k */}
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="K">S.K+</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.K+"
                        type="number"
                        step="any"
                        value={state.K}
                        onChange={(e) => {
                          setState({ ...state, K: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sLDL">S.NA+</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.NA+"
                        type="number"
                        step="any"
                        value={state.sNA}
                        onChange={(e) => {
                          setState({ ...state, sNA: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="bnp">S.BNP</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.BNP"
                        type="number"
                        step="any"
                        value={state.BNP}
                        onChange={(e) => {
                          setState({ ...state, BNP: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="troponin">S.Troponin</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.Troponin"
                        type="number"
                        step="any"
                        value={state.troponin}
                        onChange={(e) => {
                          setState({ ...state, troponin: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  {/* hb */}

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Hba1c">Hba1c</label>
                      <input
                        disabled={isComplete}
                        placeholder="Hba1c"
                        type="number"
                        step="any"
                        value={state.Hba1c}
                        onChange={(e) => {
                          setState({ ...state, Hba1c: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="rbs">rbs</label>
                      <input
                        disabled={isComplete}
                        placeholder="rbs"
                        type="number"
                        step="any"
                        value={state.rbs}
                        onChange={(e) => {
                          setState({ ...state, rbs: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="tsh">tsh</label>
                      <input
                        disabled={isComplete}
                        placeholder="tsh"
                        type="tsh"
                        value={state.tsh}
                        onChange={(e) => {
                          setState({ ...state, tsh: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="tsh">s.T4</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.T4"
                        type="S.T4"
                        value={state.t4}
                        onChange={(e) => {
                          setState({ ...state, t4: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sCholesterol">S.Cholesterol</label>
                      <input
                        type="number"
                        step="any"
                        disabled={isComplete}
                        placeholder="S.Cholesterol"
                        value={state.sCholesterol}
                        onChange={(e) => {
                          setState({ ...state, sCholesterol: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>{" "}
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sTG">S.TG</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.TG"
                        type="number"
                        step="any"
                        value={state.sTG}
                        onChange={(e) => {
                          setState({ ...state, sTG: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sLDL">S.LDL</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.LDL"
                        type="number"
                        step="any"
                        value={state.sLDL}
                        onChange={(e) => {
                          setState({ ...state, sLDL: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="sLDL">S.HBL</label>
                      <input
                        disabled={isComplete}
                        placeholder="S.HBL"
                        type="number"
                        step="any"
                        value={state.sHBL}
                        onChange={(e) => {
                          setState({ ...state, sHBL: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <hr />
                </div>
              )}
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="Temperature">Temperature</label>
                  <input
                    placeholder="Temperature"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.Temperature}
                    onChange={(e) => {
                      setState({ ...state, Temperature: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="EF">EF%</label>
                  <input
                    disabled={isComplete}
                    placeholder="EF%"
                    type="number"
                    step="any"
                    value={state.EF}
                    onChange={(e) => {
                      setState({ ...state, EF: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>{" "}
                {/* new */}
              </div>
              <hr />{" "}
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="height">height</label>
                  <input
                    disabled={isComplete}
                    placeholder="height"
                    type="number"
                    step="any"
                    value={state.height}
                    onChange={(e) => {
                      setState({
                        ...state,
                        height: e.target.value,
                        BMI: calculateBMI(e.target.value, state.weight).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="weight">weight</label>
                  <input
                    placeholder="weight"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.weight}
                    onChange={(e) => {
                      setState({
                        ...state,
                        weight: e.target.value,
                        BMI: calculateBMI(state.height, e.target.value).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="BMI">BMI </label>
                  <input
                    placeholder="BMI"
                    type="number"
                    step="any"
                    disabled
                    value={state.BMI}
                    onChange={(e) => {
                      setState({ ...state, BMI: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>
              <hr />
              <label htmlFor="other">other</label>
              <textarea
                placeholder="other"
                disabled={isComplete}
                value={state.otherGynecologyObstetric}
                onChange={(e) => {
                  setState({
                    ...state,
                    otherGynecologyObstetric: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete || disable}
                s
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
