export const ADD_DOCTOR_REQUEST = 'ADD_DOCTOR_REQUEST';
export const ADD_DOCTOR_REQUEST_SUCCESS = 'ADD_DOCTOR_REQUEST_SUCCESS';
export const ADD_DOCTOR_REQUEST_FAIL = 'ADD_DOCTOR_REQUEST_FAIL';

export const GET_DOCTOR_REQUEST = 'GET_DOCTOR_REQUEST';
export const GET_DOCTOR_REQUEST_SUCCESS = 'GET_DOCTOR_REQUEST_SUCCESS';
export const GET_DOCTOR_REQUEST_FAIL = 'GET_DOCTOR_REQUEST_FAIL';

export const DELETE_DOCTOR_REQUEST = 'DELETE_DOCTOR_REQUEST';
export const DELETE_DOCTOR_REQUEST_SUCCESS = 'DELETE_DOCTOR_REQUEST_SUCCESS';
export const DELETE_DOCTOR_REQUEST_FAIL = 'DELETE_DOCTOR_REQUEST_FAIL';

export const UPDATE_DOCTOR_REQUEST = 'UPDATE_DOCTOR_REQUEST';
export const UPDATE_DOCTOR_REQUEST_SUCCESS = 'UPDATE_DOCTOR_REQUEST_SUCCESS';
export const UPDATE_DOCTOR_REQUEST_FAIL = 'UPDATE_DOCTOR_REQUEST_FAIL';
