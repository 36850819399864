import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCESS,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCESS,
  DELETE_USERS_FAIL,
} from "../constants/userConstants";
export const userLoginReducr = (state = { isLogIn: false }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { lodaing: false, userInfo: action.payload, isLogIn: true };
    case USER_LOGIN_FAIL:
      return { loading: false, error: "User fail", isLogIn: false };
    case USER_LOGOUT:
      return { isLogIn: false };
    default:
      return state;
  }
};
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { lodaing: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: "User fail" };
    default:
      return state;
  }
};
export const getAllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case GET_ALL_USERS_SUCESS:
      return { lodaing: false, userInfo: action.payload };
    case GET_ALL_USERS_FAIL:
      return { loading: false, error: "Get all user fail" };
    default:
      return state;
  }
};
//  userInfo: [...state.filter(user => user.id !== action.payload)]
export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USERS_REQUEST:
      return { loading: true };
    case DELETE_USERS_SUCESS:
      return {
        lodaing: false,
        userInfo: action.payload,
      };
    case DELETE_USERS_FAIL:
      return { loading: false, error: "Delete user fail" };
    default:
      return state;
  }
};

export function getToken() {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  return user?.token;
}
