export const ADD_LAB_REQUEST = 'ADD_LAB_REQUEST';
export const ADD_LAB_SUCCESS = 'ADD_LAB_SUCCESS';
export const ADD_LAB_FAIL = 'ADD_LAB_FAIL';

export const GET_LAB_REQUEST = 'GET_LAB_REQUEST';
export const GET_LAB_SUCCESS = 'GET_LAB_SUCCESS';
export const GET_LAB_FAIL = 'GET_LAB_FAIL';

export const DELETE_LAB_REQUEST = 'DELETE_LAB_REQUEST';
export const DELETE_LAB_SUCCESS = 'DELETE_LAB_SUCCESS';
export const DELETE_LAB_FAIL = 'DELETE_LAB_FAIL';

export const GetFormattedDate=(todayTime)=> {
    var month = (todayTime .getMonth() + 1);
    var day = (todayTime .getDate());
    var year = (todayTime .getFullYear());
    
    if(day.toString().length==1){
        day ="0"+day;
    } if(month.toString().length==1){
        month ="0"+month;
    }
    return year + "-" +month  + "-" +day;
}