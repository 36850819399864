export const ADD_MAIN_TEST_REQUEST = 'ADD_MAIN_TEST_REQUEST';
export const ADD_MAIN_TEST_SUCCESS = 'ADD_MAIN_TEST_SUCCESS';
export const ADD_MAIN_TEST_FAIL = 'ADD_MAIN_TEST_FAIL';

export const GET_MAIN_TEST_REQUEST = 'GET_MAIN_TEST_REQUEST';
export const GET_MAIN_TEST_SUCCESS = 'GET_MAIN_TEST_SUCCESS';
export const GET_MAIN_TEST_FAIL = 'GET_MAIN_TEST_FAIL';

export const DELETE_MAIN_TEST_REQUEST = 'DELETE_MAIN_TEST_REQUEST';
export const DELETE_MAIN_TEST_SUCCESS = 'DELETE_MAIN_TEST_SUCCESS';
export const DELETE_MAIN_TEST_FAIL = 'DELETE_MAIN_TEST_FAIL';

export const UPDATE_MAIN_TEST_REQUEST = 'UPDATE_MAIN_TEST_REQUEST';
export const UPDATE_MAIN_TEST_SUCCESS = 'UPDATE_MAIN_TEST_SUCCESS';
export const UPDATE_MAIN_TEST_FAIL = 'UPDATE_MAIN_TEST_FAIL';
