import {
  ADD_PATION_REQUEST,
  ADD_PATION_SUCCESS,
  ADD_PATION_FAIL,
  GET_PATION_REQUEST,
  GET_PATION_SUCCESS,
  GET_PATION_FAIL,
  UPDATE_PATION_REQUEST,
  UPDATE_PATION_SUCCESS,
  UPDATE_PATION_FAIL,
  DELETE_PATION_REQUEST,
  DELETE_PATION_SUCCESS,
  DELETE_PATION_FAIL,
} from '../constants/newinvocesConstants';

export const addPationReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PATION_REQUEST:
      return { loading: true };
    case ADD_PATION_SUCCESS:
      return { lodaing: false, addPation: action.payload };
    case ADD_PATION_FAIL:
      return { loading: false, error: 'Add Pation fail ' };
    default:
      return state;
  }
};

export const getPationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PATION_REQUEST:
      return { loading: true };
    case GET_PATION_SUCCESS:
      return { lodaing: false, getPations: action.payload };
    case GET_PATION_FAIL:
      return { loading: false, error: 'Get Pation fail ' };
    default:
      return state;
  }
};

export const updatePationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PATION_REQUEST:
      return { loading: true };
    case UPDATE_PATION_SUCCESS:
      return { lodaing: false, getPations: action.payload };
    case UPDATE_PATION_FAIL:
      return { loading: false, error: 'Get Pation fail ' };
    default:
      return state;
  }
};

export const deletPationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PATION_REQUEST:
      return { loading: true };
    case DELETE_PATION_SUCCESS:
      return { lodaing: false, deletePation: action.payload };
    case DELETE_PATION_FAIL:
      return { loading: false, error: 'Get Pation fail ' };
    default:
      return state;
  }
};
