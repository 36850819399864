import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDrugAction,
  deleteDrug,
  getDrugsAction,
  updateDrug,
} from "../../actions/drugsAction";

function AddDrugs() {
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [updateID, setUpdateID] = useState();
  const userlogin = useSelector((state) => state.userLogin);
  const getDrugs = useSelector((state) => state.getDrugs);
  const { getDrugsData } = getDrugs;
  const { userInfo } = userlogin;
  useEffect(() => {
    dispatch(getDrugsAction(userInfo.token));
  }, []);
  const [search, setSearch] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    const { userInfo } = userlogin;
    if (name == null || name == undefined || name == "") {
      return;
    }
    if (updateID == null) {
      await addDrugAction(name, "description", reset);
      await dispatch(getDrugsAction(userInfo.token));
    } else {
      await updateDrug(userInfo.token, updateID, name, "description", reset);
      setUpdateID(null);
      await dispatch(getDrugsAction(userInfo.token));
    }
  };

  const reset = () => {
    
    setName("");
  };
  const deleteHandler = async (id) => {
    const { userInfo } = userlogin;
    
    await dispatch(deleteDrug(userInfo.token, id));

    await dispatch(getDrugsAction(userInfo.token));
  };

  const editdeleteHandler = (id, name, description) => {
    setName(name);
    setUpdateID(id);
  };
  let counterDrugs = 0;
  let drugsForm = [];
  getDrugsData?.forEach((drugs) => {
    if (drugs.name?.includes(search)) {
      counterDrugs++;
      drugsForm.push(
        <tr key={counterDrugs}>
          <td>{counterDrugs}</td>
          <td>{drugs.name}</td>

          <td>
            <button
              onClick={() =>
                editdeleteHandler(drugs.id, drugs.name, drugs.description)
              }
              className="bg-success btn-circle btn-sm"
            >
              <i className="fas fa-pen"></i>
            </button>
            <button
              onClick={() => deleteHandler(drugs.id)}
              type="button"
              className="bg-red btn-circle btn-sm"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      );
    }
  });

  return (
    <div className="card">
      <div className="card-header bg-info">
        <p>Add Drugs</p>
      </div>
      <div className="card-body w-100">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" id="addDrugsFormId" onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="drugName" className="col-form-label">
                  Name
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  id="drugName"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/*<div className="form-group">*/}
              {/*    <label htmlFor="drugDesc" className=" col-form-label">*/}
              {/*        Description*/}
              {/*    </label>*/}
              {/*    <textarea*/}
              {/*        type="textArea"*/}
              {/*        className="form-control"*/}
              {/*        required*/}
              {/*        id="drugDesc"*/}
              {/*        placeholder="Drug description"*/}
              {/*        rows="4"*/}
              {/*        value={description}*/}
              {/*        onChange={(e) => setDescription(e.target.value)}*/}
              {/*    />*/}
              {/*</div>*/}
              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  <i className="fas fa-plus"></i>{" "}
                  {updateID == null || updateID == undefined ? "Add" : "Save"}
                </button>
              </div>
            </form>
            {updateID != null && updateID != undefined ? (
              <div
                className="form-group"
                onClick={(e) => {
                  setName("");
                  setUpdateID(null);
                }}
              >
                <button className="btn btn-danger w-100">Cancel</button>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            {/*table*/}
            <div className="row m-3">
              {/*<div className="col-3 justify-content-center">*/}
              {/*    <div className="select2-purple input-group">*/}
              {/*        <label className="mr-2 mt-1">show</label>*/}
              {/*        <select className="form-control" name="state">*/}
              {/*            <option value="1">10</option>*/}
              {/*            <option value="2">20</option>*/}
              {/*            <option value="3">30</option>*/}
              {/*            <option value="4">40</option>*/}
              {/*        </select>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className="col-8">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search by Drug"
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <table className="table table-bordered  rounded-top w-100 table-striped table-responsive-lg">
              <thead className="bg-info">
                <tr>
                  <th>#</th>
                  <th>Name</th>

                  {/*<th className="w-50">Description</th>*/}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{drugsForm}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDrugs;
