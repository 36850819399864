import React from "react";
import {useSelector} from "react-redux";

function ReportTable() {
    const userlogin = useSelector(state => state.reportReducer);
    
    return (
        <div className="card">
            <div className="card-header bg-info">Result</div>
            <div className="card-body">
                <table className="table table-bordered">
                    <thead className="bg-info">
                    <tr>

                        <th>Patient number</th>
                        <th>Male</th>
                        <th>Female</th>
                        <th>intersex</th>
                        <th>cost</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>{userlogin.data.total_patients}</th>
                        <th>{userlogin.data.male}</th>
                        <th>{userlogin.data.female}</th>
                        <th>{userlogin.data.child}</th>
                        <th>{userlogin.data.total_visit_price}</th>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReportTable;