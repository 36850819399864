import {
  ADD_DOCTOR_REQUEST,
  ADD_DOCTOR_REQUEST_SUCCESS,
  ADD_DOCTOR_REQUEST_FAIL,
  GET_DOCTOR_REQUEST,
  GET_DOCTOR_REQUEST_SUCCESS,
  GET_DOCTOR_REQUEST_FAIL,
  DELETE_DOCTOR_REQUEST,
  DELETE_DOCTOR_REQUEST_SUCCESS,
  DELETE_DOCTOR_REQUEST_FAIL,
  UPDATE_DOCTOR_REQUEST,
  UPDATE_DOCTOR_REQUEST_SUCCESS,
  UPDATE_DOCTOR_REQUEST_FAIL,
} from '../constants/referaldoctorConstants';

export const referalDoctorrducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_DOCTOR_REQUEST:
      return { loading: true };
    case ADD_DOCTOR_REQUEST_SUCCESS:
      return { lodaing: false, referalDoctor: action.payload };
    case ADD_DOCTOR_REQUEST_FAIL:
      return { loading: false, error: 'Add Doctor fail ' };
    default:
      return state;
  }
};

export const getReferalDoctorrducer = (state = {
  loading: true,
  referalDoctor:[],
  error:''
}, action) => {
  switch (action.type) {
    case GET_DOCTOR_REQUEST:
      return {...state, loading: true };
    case GET_DOCTOR_REQUEST_SUCCESS:
      return {...state,  lodaing: false, referalDoctor: action.payload };
    case GET_DOCTOR_REQUEST_FAIL:
      return { ...state, loading: false, error: 'Get Doctor fail ' };
    default:
      return state;
  }
};
export const deleteReferalDoctorrducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DOCTOR_REQUEST:
      return { loading: true };
    case DELETE_DOCTOR_REQUEST_SUCCESS:
      return { lodaing: false, referalDoctor: action.payload };
    case DELETE_DOCTOR_REQUEST_FAIL:
      return { loading: false, error: 'delete Doctor fail ' };
    default:
      return state;
  }
};

export const updateReferalDoctorrducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DOCTOR_REQUEST:
      return { loading: true };
    case UPDATE_DOCTOR_REQUEST_SUCCESS:
      return { lodaing: false, referalDoctor: action.payload };
    case UPDATE_DOCTOR_REQUEST_FAIL:
      return { loading: false, error: 'delete Doctor fail ' };
    default:
      return state;
  }
};
