export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCESS = 'GET_ALL_USERS_SUCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCESS = 'DELETE_USERS_SUCESS';
export const DELETE_USERS_FAIL = 'DELETE_USERS_FAIL';

export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export  const FORCE_REFRSH ="FORCE_REFRSH";
export const EDIT_USER = "EDIT_USER";
export const OPEN_USER_ALERT = "OPEN_USER_ALERT";
export const CLOSE_USER_ALERT = "CLOSE_USER_ALERT";
export const FILTER_USER = "FILTER_USER";
export const SEARCH_USER = "SEARCH_USER";
export const DELETE_USER = "DELETE_USER";