import {
  ADD_MAIN_TEST_REQUEST,
  ADD_MAIN_TEST_SUCCESS,
  ADD_MAIN_TEST_FAIL,
  GET_MAIN_TEST_REQUEST,
  GET_MAIN_TEST_SUCCESS,
  GET_MAIN_TEST_FAIL,
  DELETE_MAIN_TEST_REQUEST,
  DELETE_MAIN_TEST_SUCCESS,
  DELETE_MAIN_TEST_FAIL,
  UPDATE_MAIN_TEST_REQUEST,
  UPDATE_MAIN_TEST_SUCCESS,
  UPDATE_MAIN_TEST_FAIL,
} from '../constants/mainTextConstatnts';

export const addMainTestReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_MAIN_TEST_REQUEST:
      return { loading: true };
    case ADD_MAIN_TEST_SUCCESS:
      return { lodaing: false, addMainTest: action.payload };
    case ADD_MAIN_TEST_FAIL:
      return { loading: false, error: 'Add Drugs fail ' };
    default:
      return state;
  }
};

export const getMainTestReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MAIN_TEST_REQUEST:
      return { loading: true };
    case GET_MAIN_TEST_SUCCESS:
      return { lodaing: false, getMainTestData: action.payload };
    case GET_MAIN_TEST_FAIL:
      return { loading: false, error: 'Get Drugs fail ' };
    default:
      return state;
  }
};

export const deleteMainTestReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MAIN_TEST_REQUEST:
      return { loading: true };
    case DELETE_MAIN_TEST_SUCCESS:
      return { lodaing: false, getMainTestData: action.payload };
    case DELETE_MAIN_TEST_FAIL:
      return { loading: false, error: 'Get Drugs fail ' };
    default:
      return state;
  }
};
export const updateMainTestReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MAIN_TEST_REQUEST:
      return { loading: true };
    case UPDATE_MAIN_TEST_SUCCESS:
      return { lodaing: false, getMainTestData: action.payload };
    case UPDATE_MAIN_TEST_FAIL:
      return { loading: false, error: 'update Drugs fail ' };
    default:
      return state;
  }
};
